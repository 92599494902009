.headingText {
	width: 75%;
}

@media (max-width: 992px) {
	.headingText {
		width: 100%;
	}

	.topHeading {
		font-size: var(--fs-52) !important;
	}

	.descriptionHeading {
		font-size: var(--fs-20) !important;
	}
}

.blackBox {
	height: 200px;
}
