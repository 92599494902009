.quiz-timeup-layout {
	background-color: #ffffff;
	padding: 32px;
	border-radius: 12px;
	width: 424px;
}

.timneup-title {
	font-size: 24px;
	line-height: 29px;
	color: #1a1a1a;
	font-weight: 700;
	margin-top: 5px;
}

.quiz-time-label {
	font-size: 16px;
	line-height: 24px;
	color: #757575;
	margin-top: 10px;
	margin-bottom: 30px !important;
}

.quiz-submit-btn {
	border-radius: 12px !important;
	background-color: #ffcd00 !important;
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
	padding: 8px 16px !important;
	width: 100% !important;
}

.borderSmallDevices {
	border: 1px solid #f1f1f1;
	margin: 0 10px;
}

.new-download-link{
	font-size: 14px;
	line-height: 21px;
	font-weight: 500;
}
