.vh-80 {
	height: 82vh;
}
.vh-62 {
	height: 62vh;
}
.disabled {
	opacity: 0.5; /* Example style for visually indicating disabled state */
	pointer-events: none; /* Optional: Prevents clicks on the button when disabled */
}
.payment-nodata-title {
	font-size: 24px;
	line-height: 29px;
	color: #22211f;
	font-weight: 700;
	margin-top: 20px;
}

.payment-nodata-label {
	font-size: 16px;
	line-height: 24px;
	color: #757575;
	max-width: 350px;
	margin: auto;
	margin-top: 15px;
	margin-bottom: 30px;
}

.payment-table-title {
	font-size: 16px;
	line-height: 24px;
	color: #1a1a1a;
	font-weight: 600;
}

.payment-table-title th {
	border: none !important;
}

.table-outer-border {
	border: 1px solid #ededed;
	border-radius: 8px;
}

.payment-inner-details {
	font-size: 14px;
	line-height: 21px;
	color: #757575;
	padding: 10px !important;
	align-content: space-around !important;
}

tr:last-child .payment-inner-details {
	padding-bottom: 15px !important;
}

.payment-inner-layout-set {
	padding-top: 22px !important;
}

td {
	border-bottom: 1px solid #d8d8d8;
	border-style: dashed;
}

tr:last-child td {
	border-bottom: none;
}

.status-label {
	font-size: 12px;
	line-height: 18px;
}

.btn-payment-space {
	padding: 6px 12px;
	font-size: 14px !important;
	line-height: 21px;
}

.payment-popup {
	position: absolute;
	z-index: 10;
	background-color: white;
	border: 1px solid #ccc;
	padding: 10px 10px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	width: 335px;
	margin-left: -330% !important;
	margin-top: -40% !important;
	border-radius: 8px;
}

.inner-popup-style {
	font-size: 14px;
	line-height: 21px;
	color: #1a1a1a;
	margin-top: 20px;
}

.inner-popup-desc {
	font-size: 12px;
	line-height: 18px;
	color: #757575;
}

.pagination-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.pagination-button {
	border: none;
	background-color: #ffffff;
	color: #1a1a1a;
	border-radius: 150px;
	padding: 4px 10px;
	margin: 0 5px;
	cursor: pointer;
	font-weight: normal;

	transition: background-color 0.3s, color 0.3s;
}

.pagination-button:hover {
	background-color: #ffffff;
	color: #1a1a1a;
}

.in-status-title {
	font-size: 14px;
	line-height: 21px;
	color: #1a1a1a;
}

.pagination-button.active {
	background-color: #f1f1f1;
	color: #1a1a1a;
	font-weight: 400;
}

.pagination-button.disabled {
	background-color: #ccc;
	color: #999;
	cursor: not-allowed;
}

.icon-btn {
	background-color: #ffffff !important;
}

.page-label {
	font-size: 14px;
	line-height: 16px;
	color: #1a1a1a;
}

.in-status-label {
	font-size: 14px;
	line-height: 21px;
	color: #757575;
	padding: 5px 0px 0px 40px;
}

.pagination-style {
	font-size: 14px;
	line-height: 16px;
	color: #1a1a1a;
}

.page-item.active .page-link {
	background-color: #f1f1f1 !important;
	color: #1a1a1a;
	font-size: 14px;
	line-height: 16px;
	border: none !important;
	border-radius: 12px;
	font-weight: 700;
}

.page-item .page-link {
	background-color: none !important;
	color: #1a1a1a;
	font-size: 14px;
	line-height: 16px;
	border: none;
	border-radius: 12px;
	font-weight: 400;
}

.sortby-dropdown {
	color: #757575;
	border: 1px solid #ededed;
	width: 280px;
}
.sortby-dropdown:hover {
	border: 1px solid #ededed;
}

.dropdown-toggle {
	min-width: 250px;
}

@media screen and (max-width: 576px) {
	.dropdown-toggle {
		min-width: 100px;
	}
}

.dropdown-toggle::after {
	position: absolute;
	top: 50%;
	right: 10px;
}

.dropdown-menu {
	width: 280px;
}

.dropdown_toggle::after {
	position: absolute;
	top: 50%;
	right: 10px;
}
.image-cross-style {
	width: 20px;
	height: 20px;
}

.sortby-title {
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
}

.dropdown-border {
	border-bottom: 1px solid #d8d8d8;
}

.form-check-label {
	font-size: 14px;
	line-height: 21px;
	color: #1a1a1a;
}

.form-check-input:checked {
	background-color: #ffcd00;
	border-color: #ffcd00 !important;
}
.form-check-input:focus {
	box-shadow: none;
}
.dp-cancel {
	border: 1px solid #757575;
	padding: 8px 32px !important;
	border-radius: 12px !important;
	font-size: 14px !important;
	line-height: 21px !important;
	color: #757575;
}

.dp-cancel:hover {
	color: #757575;
	border: 1px solid #757575;
}

.dp-apply {
	background-color: #ffcd00;
	padding: 8px 32px !important;
	border-radius: 12px !important;
	color: #1a1a1a;
	font-size: 14px !important;
	line-height: 21px !important;
}

.dp-apply:hover {
	background-color: #ffcd00;
}
.form-check-input-black:checked[type='checkbox'] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
span.flatpickr-weekday,
.flatpickr-weekdays {
	background-color: #ffcd00 !important;
	border-color: #ffcd00 !important;
	color: #1a1a1a !important;
	font-family: 'g_mid';
}
.flatpickr-day.startRange,
.flatpickr-day.endRange {
	font-family: 'g_semi';
	color: #1a1a1a !important;
	background-color: #fff000 !important;
	border-color: #fff000 !important;
	font-size: var(--fs-14);
}
/* .flatpickr-day.inRange {
  background-color: #FFF00033 !important ;
  border-color: #FFF00033 !important  ;
} */
.page_link {
	box-shadow: none !important;
}
@media only screen and (max-width: 992px) {
	.payment-inner-details {
		padding: 10px !important;
	}
	.responsive-table {
		min-width: 1200px;
	}
}
@media only screen and (max-width: 576px) {
	.dropdown-center-w-50 {
		width: 50%;
	}
}
